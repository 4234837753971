export const pincodes = [
  "400001",
  "400002",
  "400004",
  "400005",
  "400006",
  "400007",
  "400008",
  "400009",
  "400010",
  "400011",
  "400013",
  "400014",
  "400015",
  "400016",
  "400017",
  "400018",
  "400019",
  "400020",
  "400021",
  "400022",
  "400024",
  "400025",
  "400026",
  "400027",
  "400028",
  "400029",
  "400030",
  "400031",
  "400032",
  "400034",
  "400035",
  "400042",
  "400043",
  "400049",
  "400050",
  "400051",
  "400052",
  "400053",
  "400054",
  "400055",
  "400056",
  "400057",
  "400058",
  "400059",
  "400060",
  "400061",
  "400063",
  "400064",
  "400065",
  "400066",
  "400067",
  "400068",
  "400069",
  "400070",
  "400071",
  "400072",
  "400074",
  "400075",
  "400076",
  "400077",
  "400078",
  "400079",
  "400080",
  "400081",
  "400082",
  "400083",
  "400084",
  "400086",
  "400087",
  "400088",
  "400089",
  "400091",
  "400092",
  "400093",
  "400094",
  "400095",
  "400096",
  "400097",
  "400098",
  "400099",
  "400101",
  "400102",
  "400103",
  "400104",
  "400601",
  "400602",
  "400603",
  "400604",
  "400605",
  "400606",
  "400607",
  "400608",
  "400610",
  "400612",
  "400614",
  "400615",
  "400701",
  "400703",
  "400706",
  "400708",
  "400709",
  "400710",
  "401101",
  "401105",
  "401106",
  "401107",
  "401201",
  "401202",
  "401203",
  "401204",
  "401206",
  "401207",
  "401208",
  "401209",
  "401301",
  "401302",
  "401303",
  "401304",
  "401305",
  "421002",
  "421004",
  "421005",
  "421101",
  "421102",
  "421103",
  "421201",
  "421202",
  "421203",
  "421204",
  "421301",
  "421302",
  "421305",
  "421306",
  "421308",
  "421311",
  "421401",
  "421402",
  "421403",
  "421501",
  "421502",
  "421503",
  "421505",
  "421506",
  "421601",
  "421602",
  "421603",
  "421605",
];
